<template>
  <div class="box">
  <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>字典</span>
    <el-button style="float: right; padding: 3px 0" @click="addDict" type="text">操作按钮</el-button>
  </div>
  <div class="item_box" v-for="o in dictArr" :key="o"  @click="info(o.dictLabel)">
      <div>
    {{ o.dictName }}-{{ o.dictLabel }}
    
  </div>
  <div class="btn_box">
    <el-button type="primary" @click="eitDict(o)">编辑</el-button>
    <el-button type="primary" @click="delDict(o)">删除</el-button>
  </div>
 
  </div>

</el-card>
<el-card class="box-cards">
  <div slot="header" class="clearfix">
    <span>字典项</span>
    <el-button style="float: right; padding: 3px 0" type="text" @click="addItem">添加字典项</el-button>
  </div><el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="id"
        label="id"
        width="180">
      </el-table-column>
      <el-table-column
        prop="dictLabel"
        label="字典标签"
        width="180">
      </el-table-column>
      <el-table-column
        prop="item"
        label="项名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="value"
        label="项值"
        width="180">
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="180">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        width="180">
        <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
        <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
      </template>
      </el-table-column>
    </el-table>
</el-card>

    
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :modal="false"
  :before-close="handleClose">
  <el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="字典标签">
    <el-input v-model="form.dictLabel"></el-input>
  </el-form-item>
  <el-form-item label="项名">
    <el-input v-model="form.item"></el-input>
  </el-form-item>
  <el-form-item label="备注">
    <el-input v-model="form.remark"></el-input>
  </el-form-item>
  <el-form-item label="项值">
    <el-input type="textarea" v-model="form.value"></el-input>
  </el-form-item>
</el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="SubmitEit">确 定</el-button>
  </span>
</el-dialog>
<el-dialog
  :title="`${blur==false?'编辑':'添加'}`"
  :visible.sync="dialogVisibleP"
  width="30%"
  :modal="false"
  :before-close="handleClose">
  <el-form ref="Pform" :model="Pform" label-width="80px">
  <el-form-item label="名称">
    <el-input v-model="Pform.dictName"></el-input>
  </el-form-item>
  <el-form-item label="唯一标签">
    <el-input v-model="Pform.dictLabel"></el-input>
  </el-form-item>
  <el-form-item label="是否启用">
    <el-radio v-model="enabled" label="true">是</el-radio>
  <el-radio v-model="enabled" label="flase">否</el-radio>
  </el-form-item>
</el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleP = false">取 消</el-button>
    <el-button type="primary" @click="SubmitDict">确 定</el-button>
  </span>
</el-dialog>
    
</div>
</template>
<script>
import {dictList,dictListItem ,dictEdit,dictAdd,dictDel,dictEditItem,dictDelItem,dictAddItem} from '@/api/dict'
import { createLogger } from 'vuex'
export default {
  data() {
    return {
      dictArr:[],
      tableData:[],
      form:{},
      dialogVisible: false,
      dialogVisibleP:false,
      seletItem:'',
      blur:false,
      enabled:'true',
      Pform:{
        enabled:'true',
        dictName:'',
        dictLabel:'',
      }
    }
  },
  created(){
  this.ditcList()
  
},
methods: {
  delDict(v){
    console.log(v);
    dictDel({id:v.id}).then((data)=>{
      console.log(data);
      this.ditcList()

    })
  },
  ditcList(){
    dictList().then((data)=>{
      console.log(data,5151);
      this.dictArr=data.data.list
    })
  },
  SubmitDict(){
    this.Pform.enabled=this.enabled
    if(this.blur){
 dictAdd(this.Pform).then((data)=>{
  console.log(data);
  this.dialogVisibleP=false
  this.ditcList()
 })
  }else{
    dictEdit(this.Pform).then((data)=>{
  console.log(data);
  this.dialogVisibleP=false
  this.ditcList()
 })
  }
   
  },
  addDict(){
   this.Pform={},
   this.dialogVisibleP=true
   this.blur=true
  },
  eitDict(v){
    this.Pform=v,
   this.dialogVisibleP=true
   this.blur=false
  },
  addItem(){
 this.dialogVisible=true
 this.form={}
  this.blur=true
  },
  del(){
    dictDelItem(this.form).then((data)=>{
      console.log(data,145);
      this.dialogVisible=false
      this.info(this.seletItem)
    })
  },
  SubmitEit(){
    if(this.blur){
      this.form.dictLabel=this.seletItem
      dictAddItem(this.form).then((data)=>{
      console.log(data,145);
      this.dialogVisible=false
      this.info(this.seletItem)
    })
    }else{
       dictEditItem(this.form).then((data)=>{
      console.log(data,145);
      this.dialogVisible=false
      this.info(this.seletItem)
    })
    }
   
  },
  handleClick(v){
   console.log(v.row,58);
   this.form=v
   this.dialogVisible=true
   this.blur=false
  },
  info(v){
    this.seletItem=v
    dictListItem({
      dictLabel:this.seletItem
    }).then((data)=>{
      console.log(data,);
this.tableData=data.data.list
    })
  },
},
}

</script>

<style scoped>
 .box{
  display: flex;
 }
 .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
.item_box{
  display: flex;
  justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.btn_box{
  display: flex;
  justify-content: space-between;
}
  .box-card {
    width: 30%;
  }
  .box-cards {
    width: 60%;
  }
</style>